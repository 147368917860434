exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-usicom-tsx": () => import("./../../../src/pages/app-usicom.tsx" /* webpackChunkName: "component---src-pages-app-usicom-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-decarga-app-tsx": () => import("./../../../src/pages/decarga-app.tsx" /* webpackChunkName: "component---src-pages-decarga-app-tsx" */),
  "component---src-pages-distribucion-comercial-productos-tsx": () => import("./../../../src/pages/distribucion-comercial/productos.tsx" /* webpackChunkName: "component---src-pages-distribucion-comercial-productos-tsx" */),
  "component---src-pages-distribucion-comercial-sanity-template-food-services-services-slug-current-tsx": () => import("./../../../src/pages/distribucion-comercial/{sanityTemplateFoodServicesServices.slug__current}.tsx" /* webpackChunkName: "component---src-pages-distribucion-comercial-sanity-template-food-services-services-slug-current-tsx" */),
  "component---src-pages-distribucion-comercial-tsx": () => import("./../../../src/pages/distribucion-comercial.tsx" /* webpackChunkName: "component---src-pages-distribucion-comercial-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-aviso-legal-tsx": () => import("./../../../src/pages/legal/aviso-legal.tsx" /* webpackChunkName: "component---src-pages-legal-aviso-legal-tsx" */),
  "component---src-pages-legal-politica-de-cookies-tsx": () => import("./../../../src/pages/legal/politica-de-cookies.tsx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-tsx" */),
  "component---src-pages-legal-politica-de-privacidad-tsx": () => import("./../../../src/pages/legal/politica-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-legal-politica-de-privacidad-tsx" */),
  "component---src-pages-legal-tratamiento-de-datos-personales-tsx": () => import("./../../../src/pages/legal/tratamiento-de-datos-personales.tsx" /* webpackChunkName: "component---src-pages-legal-tratamiento-de-datos-personales-tsx" */),
  "component---src-pages-logistica-sanity-template-logistics-services-slug-current-tsx": () => import("./../../../src/pages/logistica/{sanityTemplateLogisticsServices.slug__current}.tsx" /* webpackChunkName: "component---src-pages-logistica-sanity-template-logistics-services-slug-current-tsx" */),
  "component---src-pages-logistica-tsx": () => import("./../../../src/pages/logistica.tsx" /* webpackChunkName: "component---src-pages-logistica-tsx" */),
  "component---src-pages-quienes-somos-trabaja-con-nosotros-tsx": () => import("./../../../src/pages/quienes-somos/trabaja-con-nosotros.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-trabaja-con-nosotros-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-templates-categorias-tsx": () => import("./../../../src/templates/categorias.tsx" /* webpackChunkName: "component---src-templates-categorias-tsx" */),
  "component---src-templates-noticia-tsx": () => import("./../../../src/templates/noticia.tsx" /* webpackChunkName: "component---src-templates-noticia-tsx" */),
  "component---src-templates-noticias-tsx": () => import("./../../../src/templates/noticias.tsx" /* webpackChunkName: "component---src-templates-noticias-tsx" */)
}

